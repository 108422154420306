import React from 'react'
import {graphql, StaticQuery} from "gatsby";
import ArticlePreview from "../../templates/articlePreview";
import CategoryTitle from "../helpers/categoryTitle";
import {isMobile} from "react-device-detect";
import cx from "classnames";
import {rightSide} from '../../utils/adUnits'
import {
  delayMultiplicationFactor,
  adDisplayDelay
} from '../../utils/articleUtil'

class PopularPosts extends React.Component {

    constructor(props) {
        super(props);
        this.className = cx(
            "hidden w-[305px] min-h-[423px] lg:flex flex-col justify-between p-4 pb-8 bg-gray-100",
            props.className
        )
    }

    /*componentDidMount() {
        setTimeout(() => {
            try {
                googletag.cmd.push(function () {
                    googletag.display('div-ht_rightcol_desktop');
                });
            } catch (e) {
                console.log(e);
            }
        }, adDisplayDelay() * delayMultiplicationFactor(isMobile));
    }*/

  componentDidMount () {
    setTimeout(() => {
      rightSide('ht_rightcol_desktop')
    }, adDisplayDelay() * delayMultiplicationFactor(isMobile));
  }

  renderItems() {
        return <StaticQuery
            query={graphql`
            query PopularPostsQuery {
              video: allDatoCmsArticle(
                    filter: {category: {elemMatch: {originalId: {in: ["39161838"]}}}, website: {elemMatch: {name: {eq: "ht"}}}}
                    limit: 1    
                    skip: 30
                ) {
                    nodes {
                        ...ArticleFieldsPluginWithPortraitImage
                    }
                }
                nonVideo: allDatoCmsArticle(
                    filter: {category: {elemMatch: {originalId: {nin: ["39161838"]}}}, website: {elemMatch: {name: {eq: "ht"}}}}
                    limit: 3
                    skip: 30
                ) {
                    nodes {
                        ...ArticleFieldsPluginWithPortraitImage
                    }
                }
            }
        `}
            render={data =>
                data.nonVideo.nodes.concat(data.video.nodes).slice(0, 3)
                    .map((article, index) => {
                    return <ArticlePreview
                    key={`top-right-${index}`} data={article}
                    className="flex w-full"
                    imageClass="mb-0 mr-2 float-left clear-both"
                    gatsbyImageClass="w-[70px] h-[70px]"
                    headingClass="font-sans font-semibold leading-snug text-lg mb-0 line-clamp l-c-3"
                    noFlag={true}
                    noBody={true}
                    noAuthor={true}
                    wrappedImg={false}
                    dateAsFlag={true}
                    titleAuthorDisplayClass={"flex-col-reverse"}
                    publishedAtFormat={"mmmm dd, yyyy"}/>
                })
            }
        />
    }

    render() {
        return !isMobile && <div className={"popular-posts article-popular relative ml-4 min-w-[305px]"}>
            <div className={this.className} data-datocms-noindex>
                <CategoryTitle title="Popular Posts"/>
                {this.renderItems()}
            </div>
          <div className="ad-container min-w-[305px] my-2">
            <div className="advertisement-text">Advertisement</div>
            <div id="ht_rightcol_desktop"/>
          </div>
        </div>
    }
}

export default PopularPosts;
